import { signIn, signOut, useSession } from 'next-auth/react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Head } from '@components/Head'
import styles from '../styles/Home.module.css'

import type { NextPage } from 'next'

const LandingPage: NextPage = () => {
  const { data: session } = useSession()
  const { t } = useTranslation()
  return (
    <div className={styles.container}>
      <Head title="home" />
      <main className={styles.main}>
        <Typography variant="h1">
          <a href="https://aboga.me">Aboga.me</a>
        </Typography>
        <Typography variant="subtitle1">{t('Welcome to React')}</Typography>
          {!session
            ? (
              <>Not signed in
              <button onClick={() => signIn()}>Sign in</button>
              </>
              )
            : (
              <button onClick={() => signOut()}>Sign out</button>
              ) }
      </main>
    </div>
  )
}

export default LandingPage
