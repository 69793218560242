import NextHead from 'next/head'

export type HeadProps = {
    title: string, description?: string
}

export const Head = ({ title }: HeadProps) => (
    <NextHead>
        <title>Aboga.me - {title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1 maximum-scale=1.0, user-scalable=no" key="viewport" />

    </NextHead>
)
